import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="श्री गुरूगजाननलीला ग्रंथ - अध्याय १६ वा" />
        <HeaderGeneric title="श्री गुरूगजाननलीला ग्रंथ" adhyaay='अध्याय १६ वा' num='16'/>
        <div id="main">
          <section id="content" className="main">
            <p>
            श्री गणेशाय नम: । व्याधी उपाधी हरणार्‍या परमेश्वरा । सहाय्य करावे लेकरा । धावुन यावे सत्वरा ॥ १ ॥
तू व्याधी उपाधी हरतो । गणामहाराज जाणतो । तुझ्यावर भार ठेवतो । तुला वारंवार विनवितो ॥ २ ॥
नको ठेवू उपाधी चिंतनात । तेणे न स्वस्थता चित्तात । मन गुंतू दे नामस्मरणात । चित्ता शांती लाभण्यात ॥ ३ ॥
नामस्मरणात सारे मिळते । शाश्वत अशाश्वत कळते । शाश्वतावर प्रेम जडते । शाश्वत प्राप्त होते ॥ ४ ॥
नको नको ती उपाधी । नको नको ती व्याधी । कोण स्विकारेल व्याधी उपाधी । इतुकी न ती साधी सुधी ॥ ५ ॥
मज काय हवे ते जाणावे । सर्वतोपरी सहाय्य करावे । ग्रंथलेखन सुलभ व्हावे । चित्त कुठे न गुंतावे ॥ ६ ॥
गुंतण्यातच होतो गुंता । काही केल्या न सुटता सुटता । अलीप्तात न बाधे गुंता । अलीप्तच ठरतो त्राता ॥ ७ ॥
माझी विनवणी ऐकावी । कृपादृष्टी लाभावी । ऐसी कृती करवावी । दुसर्‍यांच्या हृदयी वसावी ॥ ८ ॥
कृपादृष्टीत मिळते सारे । आत्मस्तुतीत जाते सारे । हे तत्व जे जे जाणणारे । तेच तुझ्यावर भरवसा ठेवणारे ॥ ९ ॥
नको गुंतवू मज आत्मस्तुतीत । चित्त राहू दे ग्रंथलेखनात । असता विचलीत चित्तात । काय उतरणार लेखनात ? ॥ १० ॥
निष्ठेत सारे मिळते । निष्ठेवर अवलंबण्याते । त्याचे महत्व कळते । हे तत्व थोड्यांना कळते ॥ ११ ॥
जो जाणतो महत्व निष्ठेचे । तो न ऐकणार दुसर्‍याचे । तो ऐकणार फक्त एकाचे । म्हणजे अंतरात्म्याचे ॥ १२ ॥
ऐसे होते पुंडलीक भोकरेचे । त्याने न ऐकले दुसरे कुणाचे । त्याने ऐकले अंतरात्म्याचे । अगदी आतल्या आवाजाचे ॥ १३ ॥
मुंडगावचा पुंडलीक । महाराजांचा भक्त एक । गावची ठाकरीण सांगे अनेक । परावृत्तीचे प्रयत्न अनेक ॥ १४ ॥
चित्त भुलवे नाना तर्‍हेने । त्यास विविध प्रलोभने । येनकेन प्रकाराने । आपले ऐकावे पुंडलीकाने ॥ १५ ॥
ठाकरीण म्हणाली त्यास । उद्या जाऊ अंजनगावास । केजाजीचा शिष्य काशीनाथास । आपला गुरू करण्यास ॥ १६ ॥
नाना प्रश्र्न विचारल्यास । समाधान होईल आपणास । नको गुरू करू गजाननास । काय ज्ञान आहे त्यास ? ॥ १७ ॥
नको नंगा पिसा अवलीया । काशिनाथाच्या पडू पाया । तोच योग्य गुरू करावया । जाऊ त्याचा उपदेश घ्यावया ॥ १८ ॥
हे न खपले पुंडलीकास । तो म्हणाला ठाकरीणीस । गुरू नको उतरवू तुलनेस । मी करणार गुरू गजाननास ॥ १९ ॥
तुलनेत होतो र्‍हास । तुलनेत होतो वनवास । निष्ठेने जडतो सत्सहवास । जेणे भोजन सुग्रास ॥ २० ॥
ठाकरीण गेली आपल्या घराला । पुंडलीक रात्री झोपी गेला । निष्ठेचा परिणाम झाला । गुरू गजानन स्वप्नी आला ॥ २१ ॥
वदता झाला पुंडलीकास । नको जाऊ अंजनगावास । कानात सांगू लागला त्यास । नको विसरू “गण गण गणात बोते”स ॥ २२ ॥
का रे अस्वस्थ चित्तास ? । काय ओढ तुझ्या चित्तात ? । एवढा कुठच्या तू विचारात ? । हितच ठरेल वदण्यात ॥ २३ ॥
पादुका मागितल्या पुंडलीकाने । म्हणे पूजा करीन नित्यनेमाने । पादुकांच्या ओढीने । वेड लावले मज त्याने ॥ २४ ॥
समर्थे ओळखले पुंडलीकास । काय हेतू त्याच्या मनास । योग्य वाटला गजाननास । जेणे पादुका दिल्यात पुंडलीकास ॥ २५ ॥
आनंद झाला पुंडलीकास । जागे केले पुंडलीकास । डोळ्यांवर न बसे विश्वास । म्हणे खरेपणा की स्वप्नास ? ॥ २६ ॥
दिवस उजाडला । ठाकरीण आली घराला । म्हणे जाऊ अंजनगावाला । काशीनाथ गुरू करायला ॥ २७ ॥
नकार पुंडलीके दिला । निश्चय ठाम सांगितला । करणार गुरू गजाननाला । जेणे मज पादुका दिल्या ॥ २८ ॥
स्वप्न डोळ्यासमोर दिसले । स्वप्नातच मन गुंतले । गजाननातच मन गुंतले । त्याच्या पादुकांत गुंतले ॥ २९ ॥
एक एक क्षण विलक्षण वाटला । स्वप्न खरे खोटे करण्याचा क्षण आला । गुरूनिष्ठा पडताळण्याचा प्रसंग आला ॥ ३० ॥
म्हणे महाराज तुम्ही फसवलेत ना ? । स्वप्नाने गोंधळलो मना । खरेच मानले मी स्वप्ना । ऐसा नको प्रसंग कुणा ॥ ३१ ॥
तुमच्यावर ठेवली निष्ठा । ठाकरीण घालवली आता । हातचे सोडून पळता । काही न आले हाती आता ॥ ३२ ॥
नाही मी इकडचा तिकडचा । नाही झालो मी कुणाचा । स्वप्नातल्या पादुकांचा । मज मोह झाला त्यांचा ॥ ३३ ॥
स्वप्नाने दिला धोका । नाही मिळाल्यात पादुका । ह्या अभाग्या पुंडलीका । नशीबाने दिला धोका ॥ ३५ ॥
काय मी पूजा करणार ? । काय मी पदरात पाडणार ? । मी दुर्दैवीच रहाणार । गुरू प्राप्ती न मज होणार ॥ ३६ ॥
खरे मानू मी कशास ? । स्वप्नास की सत्यास ? । ठेवू कशावर विश्वास ? । प्रत्यक्षास की स्वप्नास ? ॥ ३७ ॥
नको नको ते मनाचे खेळ । न बसणार त्यात मेळ । न जाणे कुणी विधात्याची वेळ । सारे त्याचेच चालती खेळ ॥ ३८ ॥
पुंडलीक विचारात पडला । मनी अस्वस्थ झाला । पुण्य क्षण उदयास आला । माहित नव्हते पुंडलीकाला ॥ ३९ ॥
दुसरा प्रहर संपत आला । अगदी ऐन दुपारच्या समयाला । झ्यामसिंग पुंडलीकाच्या घराला । मोठ्या कुतुहलाने आला ॥ ४० ॥
झ्यामसिंग होता चतुर भला । पुंडलीकाला बोलका केला । स्वप्नाचा वृत्तांत ऐकला । निष्ठेचा प्रताप कळला ॥ ४१ ॥
पुंडलीक म्हणे झ्यामसिंगाला । तू गेला होतास शेगावला । काय गुरूंचा आदेश झाला ? । तो न मज सांगितला ? ॥ ४२ ॥
मजसाठी पादुका का न धाडल्या ? । ज्या मी स्वप्नात पाहिल्या ? । त्यांच्यावर विश्वास ठेवला । जेणे गुरू गजानन केला ॥ ४३ ॥
झोळीत पादुका ठेवलेल्या । झ्यामसिंग देता झाला । आनंदाश्रू नयनाला । आले पुंडलीकाला ॥ ४४ ॥
अंगावर रोमांच उभा राहिला । सत्य असत्य पडताळता झाला । मनाचा भ्रम दूर झाला । गुरूनिष्ठेचा महिमा पटला ॥ ४५ ॥
झ्यामसिंग बोले पुंडलीकास । दोन दिवसापूर्वी जाता शेगावास । देते झाले पादुकांस । म्हणती द्याव्या पुंडलीकास ॥ ४६ ॥
घेतल्या पादुका पुंडलीकाने । भक्तीची श्रद्धेची वाहिली सुमने । नाचू लागला आनंदाने । म्हणे यावे आता ठाकरीणीने ॥ ४७ ॥
वृत्तांत ठाकरीणीस कळले । तिने पुंडलीकास ओळखले । तिचे मन खाऊ लागले । निष्ठेचे फळ समजले ॥ ४८ ॥
भक्ती एवढी उच्च कोटीची । हकीकत खरी ठरे स्वप्नाची । ओढ असता अंतःकरणाची । तेथेच येते प्रचिती ॥ ४९ ॥
गणामहाराज सांगे सर्वांस । विसरू नका पुंडलीकास । त्याच्या गुरूनिष्ठेस । अंतःकरणाच्या ओढीस ॥ ५० ॥
खर्‍या तळमळीवाचून । खर्‍या ध्यासावाचून । चिकाटी प्रयत्नांवाचून । काही न येते जुळून ॥ ५१ ॥
मुंडगावचा जसा पुंडलीक । तैसे समर्थांचे भक्त कित्येक । भाऊ कवर होता त्यात । ठरला निष्ठेचे प्रतिक ॥ ५२ ॥
राजाराम कवर अकोल्याचा । धंदा होता सावकारीचा । होता धार्मिक वृत्तीचा । मेळ बसे प्रपंचाचा ॥ ५३ ॥
दोन पुत्र राजारामास । गोपाळ म्हणती थोरल्यास । त्र्यंबक नाव धाकट्यास । परी व्यवहारी नाव भाऊ त्यास ॥ ५४ ॥
राजाराम होता परमभक्त । गजाननासी एकनिष्ठ । मधुन मधुन शेगावात । दर्शने मन होई तृप्त ॥ ५५ ॥
त्यांच्या धाकट्या मुलास । त्या भाऊ कवरास । डॉक्टरी विद्या शिकण्यास । जावे लागले हैद्राबादेस ॥ ५६ ॥
सुट्टीत येता अकोल्यास । परी चैन न पडे त्यास । वाटे एकदा जावे शेगावास । समर्थांच्या दर्शनास ॥ ५७ ॥
असाच एकदा अकोल्यास असता । दर्शनाची ओढ लागली चित्ता । पिठल भाकर प्रसादास नेऊ म्हणता । कुणास सांगावे आता ? ॥ ५८ ॥
लहानपणीच निवर्तली माता । कोण पुरवील हट्टा ? । भावजयीस सांगू म्हणता । भावजयीस घाबरत होता ॥ ५९ ॥
गुरूभेटीची ओढ मना । स्वस्थता नव्हती मना । कैसे बोलावे सुचेना । मार्ग काही निघेना ॥ ६० ॥
अवधी थोडा गेला । सांगण्यास उतावीळ झाला । आज्ञा मागता झाला । गुरूभेटीचा हेतू सांगितला ॥ ६१ ॥
भावजय म्हणाली त्याला । एवढेच ना ! करते पिठल भाकरीला । घेऊन जा शेगावला । खाऊ घालावे गुरूला ॥ ६२ ॥
भाऊ कवर खुष झाला । भावजयीचे मन जाणता झाला । मातृवात्सल्याचा भाव दिसला । म्हणे उगाच घाबरलो हिला ॥ ६३ ॥
भावजयीने स्वयंपाक केला । पिठल भाकरीचा बेत झाला । भाऊ म्हणे तिला । तुझ्यामुळेच जाणे शेगावला ॥ ६४ ॥
लोण्याने माखलेल्या तीन भाकरी । तीन कांदे, मिरच्या ओंजळीभरी । पिठले होते बरोबरी । सारे घेऊन निघाली स्वारी ॥ ६५ ॥
लगबगीने आला स्टेशनात । झाला पहाता घड्याळात । गाडी न दिसली स्टेशनात । म्हणे उशीर झाला येण्यात ॥ ६६ ॥
झाला हताश चित्तात । बसला निराशेच्या सुरात । दुसर्‍या गाडीची वाट पाहाण्यात । तब्बल तीन तास त्यात ॥ ६७ ॥
मनात गुरूंचा धावा केला । उशीर झाला येण्याला । माफ करावे मला । परी आधी प्रसाद तुम्हाला ॥ ६८ ॥
भाऊ स्टेशनात तिष्ठत बसला । जेवल्यावाचून बसला । इकडे शेगावी प्रकार वेगळा । समर्थे नाकारले भोजनाला ॥ ६९ ॥
समर्थ भोजन घेईनात । कुणाचा प्रसाद घेईनात । सर्वच भक्त मन वळवण्यात । परी समर्थ काही बोलेनात ॥ ७० ॥
मनाचा हेतू सांगेनात । यश न येई त्यांच्या प्रयत्नात । केल्या विनवण्या नाना । कठीण मनधरणी करण्या ॥ ७१ ॥
गुरू काही जेवेनात । तेणे कुणीही जेवेनात । नाना प्रश्र्न विचारण्यात । परी यश न येई त्यात ॥ ७२ ॥
महाराज रंगले मंत्रजपात । एका भक्ताच्या चिंतनात । नच रमले पंचपक्वान्नात । परी होते भाकरीच्या तुकड्यात ॥ ७३ ॥
इकडे भाऊ गाडीत बसलेला । शेगावचा विचार चाललेला । मुळातच उशीर झालेला । मनस्थितीने गोंधळलेला ॥ ७४ ॥
आशा निराशेच्या चक्रात सापडलेला । गुरूभेटीची तळमळ असलेला । भेटीला उत्सुक असलेला । मनात दुसरा विचार नसलेला ॥ ७५ ॥
डब्यातील माणसे बघत होती । वेगळेच अनुभवत होती । त्याच्या डोळ्यांत आसवे होती । गुरू भेटीची वाट पहात होती ॥ ७६ ॥
भाऊ बोलू शकत नव्हता । गुरूंच्या तंद्रीत मग्न होता । खुणेनेच सर्वांना दूर करता । शेगावातच मग्न होता ॥ ७७ ॥
भाऊस काही न दिसे गुरूवीण । हे मनातले ओळखणार कोण ? । ऐसा दुर्लभ भक्त कोण ? । जो न जगे गुरूवीण ॥ ७८ ॥
एक एक स्टेशन जात होते । पुढचेच विचार येत होते । तळमळीला वाढवत होते । वेगळ्याच विचारात नेत होते ॥ ७९ ॥
गुरू भेटीत एवढा अधीर । कैसा रहाणार त्यास धीर । जो गुरूतच झालेला स्थीर । कैसा रहाणार गाडीत स्थीर ॥ ८० ॥
गाडीत होता देहाने । परी शेगावी होता मनाने । त्यास कुणी न जाणे । जेणे नाना प्रश्र्न विचारणे ॥ ८१ ॥
पहाता पहाता शेगाव आले । केव्हा उतरतो ऐसे झाले । स्टेशनात गाडी येता हळू चाले । थांबल्याविणा न उतरणे झाले ॥ ८२ ॥
वासराने गोठ्याची वाट धरावी । तैसी भाऊची स्थिती दिसावी । कृतीने मूर्ती बोलकी व्हावी । ऐसी ओढ त्याच्या हृदयी ॥ ८३ ॥
पाऊल पुढे टाकावे लागत नव्हते । मनोवेगे शरीर उसळत होते । भाऊस काय झाले होते । बघणार्‍यास कळत नव्हते ॥ ८४ ॥
धावत धावत मठात आला । महाराजांस पहाता झाला । सारा परिवार उदास दिसला । मनोमन कावरा बावरा झाला ॥ ८५ ॥
कुणीही काहिही बोलेना । त्याला काहिही कळेना । महाराज भेटतीलच वाटेना । शंका कुशंका मनी नाना ॥ ८६ ॥
इकडे तिकडे फिरू लागला । तळमळीचा अंत आला । गुरू बसलेले दिसले त्याला । सुटकेचा श्वास टाकला ॥ ८७ ॥
भाऊस महाराज म्हणाले । अरे किती तिष्ठत ठेवले । मला उपाशी रहावे लागले । जेणे कुणीच न जेवले ॥ ८८ ॥
काढ लोणी लावलेल्या भाकरी । मिरच्या ओंजळीभरी । कांदे पिठले बरोबरी । काढ सर्व शिदोरी ॥ ८९ ॥
भक्त भाऊकडे बघते झाले । एका प्रसंगातून मुक्त झाले । प्रसाद समर्थांपुढे ठेवते झाले । भाऊने समर्थांस विनविले ॥ ९० ॥
भाऊस बोलून उपयोग नव्हते । जे ते मनात समजत होते । गुरू ईच्छा मोडवत नव्हते । ऐसे असते गुरू-शिष्य नाते ॥ ९१ ॥
एकमेक एकमेकांची वाट पहाती । धन्य ती गुरूभक्ती । जीवनी आदर्श जी ती । ठसवावी आपल्या चित्ती ॥ ९२ ॥
भक्ती ऐशी करावी । गुरूने भक्ताची वाट पहावी । भक्ताची शिदोरी पुढ्यात घ्यावी । त्यात काया धन्य मानावी ॥ ९३ ॥
अंतरंगात हवी तळमळ । जेणे गुरू गजानन करे जवळ । प्रसादास न पडे भुरळ । हेच ठसवावे खोल ॥ ९४ ॥
मन म्हटले की चिंतन होणार । कधी न रिकामे रहाणार । देह म्हटला की रोग होणार । कुणास न सोडणार ॥ ९५ ॥
रोग जितक्या अवधीने येतो । तितुक्या अवधीने जातो । रोगानुसार त्रास उद्भवतो । तेणे जीव त्रस्त होतो ॥ ९६ ॥
चिंतेचेही असेच असते । चिंतेचे चिंतन करण्याते । चिंता अधिक तीव्र होते । तेणे जीवास चैन न पडते ॥ ९७ ॥
करू नये चिंतेचे चिंतन । घेऊ नये जीव जाळून । करावे नामाचे स्मरण । तेणे भवभय चिंता हरण ॥ ९८ ॥
नामच करे चिंता हरण । जेणे सुसंगत जीवन । जगू नये नामावाचून । तुकाराम होता तत्व जाणून ॥ ९९ ॥
तुकाराम शेगोका शेतकरी । समर्थांवर जीव भारी । दर्शनास येई वरचेवरी । त्यात जीव रमे भारी ॥ १०० ॥
दर्शनास यावे । चिलिमीस भरावे । समर्थांजवळ बसावे । हवे नको ते पहावे ॥ १०१ ॥
हे त्याचे नित्य असे । त्यात खंडण कधी नसे । परी शेती-व्यापार सुटत नसे । प्रपंचयुक्त परमार्थ असे ॥ १०२ ॥
एक दिवस उजाडला । प्रसंग निमित्त ठरला । शेतात ससा पळत राहिला । एक शिकारी शोधत राहिला ॥ १०३ ॥
ससा काही मिळेना । शिकारी त्यास सोडेना । शिकार केल्याविणा । चैन त्यास पडेना ॥ १०४ ॥
ससा पळताना दिसला । शिकार्‍याने बंदुकीचा नेम धरला । गोळी न लागली सशाला । परी तुक्याच्या कानाला ॥ १०५ ॥
तुक्या रक्तबंबाळ झाला । डॉक्टरी इलाज सुरू झाला । कालावधी लोटला । परी आराम न पडला ॥ १०६ ॥
डोके काही थांबेना । काम काही सुचेना । लोक भेटले नाना । सल्ले देते झाले करण्या ॥ १०७ ॥
उपाय निरुपाय ठरले । दुखणे बरे न झाले । डोके दुखतच राहिले । काय करावे सुचेनासे झाले ॥ १०८ ॥
सुरुवात म्हटली की अंत येणार । कुणी न अपवाद ठरणार । वेळही निश्चित असणार । एखादाच ती जाणणार ॥ १०९ ॥
अनेक जणांचे सल्ले । तुक्यास घ्यावे लागले । एकाने तुक्यास सुचवले । समर्थांस का न विचारले ? ॥ ११० ॥
तू गुरूसेवेत रहावे । मठात झाडलोट करावे । छर्‍यास घालवावे । व्याधी उपाधीस टाळावे ॥ १११ ॥
तुक्यास म्हणणे पटले । मठाचे झाडणे सुरू केले । मनोभावे सेवेस आचरले । त्याच्या सत्व परिक्षेस पाहिले ॥ ११२ ॥
वर्षामागून वर्षे गेली । तुक्याची सेवा चालूच राहिली । श्रद्धेने झाडलोट केली । शेवटी श्रद्धाच फळास आली ॥ ११३ ॥
चौदा वर्षे झाडले मठास । कधी न कंटाळा आला त्यास । ठेवला सेवेवर विश्वास । मुक्त झाला वनवासास ॥ ११४ ॥
मठ झाडता झाडता । एक दिवस झाला उजाडता । छरा पडला झाडता झाडता । दुखणे घेऊन गेला जाता जाता ॥ ११५ ॥
तुकारामास झाला आराम । असा गुरूसेवेचा परिणाम । ज्याचा निश्चय असे ठाम । गुरूच वाटे त्याला राम ॥ ११६ ॥
गणामहाराज भक्तांस सांगे । नामाने व्हावे जागे । श्रद्धेने नामाच्या संगे । जीवन जगावे सत्संगे ॥ ११७ ॥
जशी गुरूनिष्ठा फळास पुंडलीकास । गुरूभक्ती तळमळ भाऊ कवरास । गुरूसेवा तुकारामास । तशीच फळास येवो सर्व भक्तांस ॥ ११८ ॥
गणामहाराज सांगे सर्व भक्तांस । विसरू नका कधी नामास । नामच नेईल पैलतीरास । येथेच सोळावा अध्याय जातो पूर्णत्वास ॥ ११९ ॥

॥ शुभं भवतु ॥
॥ श्री गणपती सरस्वतीर्पणमस्तु ॥
॥ इति श्रीगुरूगजाननलीला ग्रंथस्य षोडशोऽध्यायः संपूर्णः ॥
</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
